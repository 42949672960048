<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default {
  name: 'App'
}
</script>

<style>
#app {
  margin: 0px auto /* 去除上下的边距*/
}
</style>
