import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

export const constantRouterMap = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/login.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login.vue')
    },
    {
        path: '/main',
        name: 'main',
        component: () => import('@/views/main.vue')
    },
    {
        path: '/svg',
        name: 'svg',
        component: () => import('@/views/svg.vue')
    }
]

let router = new Router({
    mode: 'history',
    routes: constantRouterMap
})


var getLastUrl = (str, yourStr) => str.slice(str.lastIndexOf(yourStr))
export default router
